.blogmain-wrapper {}

.blog-nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em 0;
}

.blog-nav-wrapper h1 {
  margin: 0;
  padding: 0;
}

.port-back-btn {
  outline: none;
  width: 80%;
}

.port-back-btn:hover,
.port-back-btn:focus {
  outline: none;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

.h-link:hover,
.h-link:focus {
  color: lightcoral;
}

.link:visited {
  color: black;
}

.blog-link {
  text-decoration: none;
  font-weight: 500;
  color: darkblue;
}

.blog-link:hover,
.blog-link:focus {
  color: lightcoral;
}