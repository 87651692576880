.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  color: black;
  font-weight: 700;
  transition: text-shadow 0.3s linear;
}

.header:hover {
  text-shadow: 0.1em 0.1em red, -0.1em -0.1em cyan;
}

.header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
}

.nav-icon {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .header-right {
    display: none;
  }
}