/* Layout */

a {
    font-weight: 300;
}

/* a:hover,
a:focus {
    color: black;
} */

.cover-page-wrapper {
    height: 100%;
    width: 100%;
}

.cover-page-wrapper p {
    max-width: 26em;
}

.ext-link {
    text-decoration: none;
    color: #121314;
    position: relative;
}

.ext-link:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 65%;
    left: -0.1em;
    right: -0.1em;
    bottom: 0;
    transition: top 200ms cubic-bezier(0, .8, .13, 1);
    background-color: lightcoral !important;
}

.ext-link:hover:after {
    top: 0%;
}


.port-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: #121314;
    font-size: 1em;
    font-weight: 300;
    background-image: linear-gradient(0deg, lightcoral 0%, lightcoral 40%, white 20%);

}

.port-btn:hover,
.port-btn:focus {
    background-color: lightcoral;
    outline: none;
}