.App {
    display: flex;
    z-index: -2;
    flex-direction: column;
    justify-content: flex-start;
    padding: 3em;
    margin: 0;
}

h1 {
    font-size: 4.5em;
    font-weight: 500;
    margin-bottom: 0;
}

h2 {
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 0;
}

p {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.4;
}